import React from 'react';
import './App.css';
import SignupComponent from './components/SignupComponent';
import MarkdownViewer from './components/MarkdownViewer';
import intro from './resources/markdown/intro.md'
import faq from './resources/markdown/faq.md'
import TopBannerComponent from './components/TopBannerComponent';

function App() {
    
  return (
    <div className="App">
      <header className="App-header">

      <div className='TopBanner'>
        <TopBannerComponent/>
      </div>
      

      <div className='Intro'>
        <MarkdownViewer markdownUrl={intro}/>  
      </div> 
      <div className='ImageSpacer'>         
        <img src="/crowd_scene_green.png" alt='festival crowd'/>
      </div>

      <div className='Faq'>
        <MarkdownViewer markdownUrl={faq}/>  
      </div>

      <div className='SignupForm'>
      <SignupComponent/>
      </div>

      </header>
    </div>
  );
}

export default App;
