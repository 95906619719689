import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useState, ChangeEvent, FormEvent } from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import { useForm, Controller } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form';
import SendIcon from '@mui/icons-material/Send'; 
import axios, {AxiosError, AxiosResponse} from 'axios';import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Today } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface FormData {
  name: string;
  email: string;
  mobile: string;
  birthdate: Date;
  comment: string;
}

  let msg_sent:boolean = false;
  let snack_message:string = "ta' det roligt din tilmelding er sendt :-)";
  let posted_data:FormData|null = null;
  const SignupComponent: React.FC = () => {
  
  // Brug useForm hook til at initialisere formular
  const { control, handleSubmit } = useForm<FormData>();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState<string | null>(null);

  const dataIsDifferent = (data:FormData, posted_data:FormData|null) => {
    if (posted_data === null) return true;
    return posted_data.name !== data.name ||
          posted_data.email !== data.email ||
          posted_data.mobile !== data.mobile ||
          posted_data.birthdate !== data.birthdate ||
          posted_data.comment !== data.comment;
  };

  const onSubmit: SubmitHandler<FormData> = async (data:FormData) => {
    if (msg_sent === false || dataIsDifferent(data, posted_data)){
      msg_sent = true;
      posted_data = data;
      const response = await axios.post('https://api.evang.dk/rf-vol/signup', data)
      .then((response:AxiosResponse) => {
        setApiResponse(response.data);
        setSuccessDialogOpen(true);
        msg_sent=true;
      })
      .catch((e: AxiosError) =>{
        // any error?
      })
    }
    else
    {
      //snack_message = "Dine oplysninger er allerede sendt..."
      setOpen(true)
      //return;
    }

    
  };
 
    const handleDialogClose = () => {
      // Close the dialog and reset state
      setSuccessDialogOpen(false);
    };

    const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
     
  return (
      <div>
      <h1>Tilmelding til Roskilde Festival med HTKD</h1>
      <p>Du er i gang med at tilmelde dig som frivillig til Rosilde Festival i HTKD's pantbod d 3/7 - 7/7 2024.
        Du er først tilmeldt, når du har modtaget en mail fra klubben, om at du er tilmeldt.
        Tilmeldingerne behandles i den rækkefølge, de kommer ind, og der er begrænsede pladser til unge under 18 år
        I udgangspunkt er din tilmelding bindende,
        hvis du efter din tilmelding har fortrudt, er det vigtigt at du tager kontakt til de ansvarlige hurtigst muligt</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Brug Controller-komponenten til at forbinde inputfeltet med formen */}

        <Grid container spacing={2}>
          <Grid item xs={12}>

            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Navn og efternavn"
                  variant="outlined"
                  fullWidth />
              )} />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="E-mail"
                  variant="outlined"
                  type="email"
                  fullWidth />
              )} />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="mobile"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Mobilnummer"
                  variant="outlined"
                  type="tel"
                  fullWidth />
              )} />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="birthdate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="fødselsdato - du skal være min. 15 år når festivalen starter"
                  variant="outlined"
                  type="date"
                  fullWidth />
              )} />
          </Grid>


          <Grid item xs={12}>
            <Controller
              name="comment"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Tiføj evt en kommentart til din tilmelding"
                  multiline
                  rows={3}
                  variant="outlined"
                  fullWidth />
              )} />
          </Grid>

          <Grid item xs={12}>

            <Button type="submit" style={{ backgroundColor: "#ff8200", padding: "2em" }} variant="contained" endIcon={<SendIcon />}>
              Yes! Jeg vil med på festival!
            </Button>
          </Grid>
        </Grid>
      </form>

      <Dialog open={successDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Tak for din tilmelding til pantboden på Roskilde Festival 2024</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Du kan forvente en bekræftelse indenfor 2 dage. Din tilmelding er kun gyldig, når du har modtaget din bekræftelse. Tilmeldinger til pantboden bliver behandlet i den rækkefølge, de kommer ind
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Luk
          </Button>
        </DialogActions>
      </Dialog>
    
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}                
      >
        <span>{snack_message}</span>
      </Snackbar>
      


    </div>

  );
};

export default SignupComponent;

